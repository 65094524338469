.contact-stripe{
    width: 100vw;
    height: 4rem;
    display: flex;
    background-color: #000000;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.contact-title{
    color: #FFFFFF;
    font-family: 'Bebas Neue';
    font-size: 2.4rem;
}
.contact-container{
    background-color: #EAECF4;
    width: 100vw;
    display: flex;
}
.contact-form-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;

}
.contact-form{
    border-top-style: solid;
    border-top-width: 0.4rem;
    border-top-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    padding-top: 1.6rem;
    margin-top: 1.6rem;
    background: #FFFFFF;
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
}
.form-input{
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: #949191;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
}
#name-field{
    width: 90%;
    margin-bottom: 1rem;
}
#email-field{
    width: 42%;
    margin-right: 1rem;
    margin-left: 1.2rem;
    margin-bottom: 1.2rem;
}
#tel-field{
    width: 44%;
}
.message-label{
    font-family: 'Nunito', sans-serif;
    margin-right: 0.4rem;
    color: #7C7E88;
}
.message{
    vertical-align: top;
    border: none;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #7C7E88;
    resize: none;
    font-family: 'Nunito';
}
.submit-form{
    margin-top: 1.6rem;
    background-color: #4DAF63;
    border-style: none;
    width: 35%;
    height: 2rem;
    border-radius: 1.6rem;
    color: #EAECF4;
    font-family: 'Bebas Neue';
    font-size: 1.4rem;
}

@media (min-width: 900px) {
    .contact-form {
        width: 30rem;
    }
    #name-field{
        width: 82%;
    }
    #tel-field{
        width: 45%;
    }
}
