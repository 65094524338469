.services-container{
    background-color: #000000;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.services {
    width: 35%;
    margin-top: 2rem;
    padding-bottom: 2rem;
}
#creation {
    animation: fadeServices 4s ease  1 none;
}
#production {
    animation: fadeServices 4s ease  1 none;
}
#editing {
    animation: fadeServices 4s ease  1 none;
}
@keyframes fadeServices {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-webkit-keyframes fadeServices {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-o-keyframes fadeServices {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-moz-keyframes fadeServices {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-ms-keyframes fadeServices {
    from {opacity: 0;}
    to {opacity: 1;}
}


@media (min-width: 900px) {
    .services-container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    .services {
        width: 13%;
    }
}
