.pages-separation {
    background-color: #EAECF4;
    width: 100%;
    height: 3.4rem;
}
.gif-stripe{
    background-color: #000000;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.gifs {
    width: 70%;
    align-self: center;
    justify-self: center;
}
.about-title {
    background-color: #EAECF4;
    font-family: 'Bebas Neue';
    font-weight: 700;
    font-size: 4rem;
    padding-left: 1.6rem;
    padding-top: 1.2rem;
}
.about-page-container {
    background-color: #EAECF4;
}
.about-text{
    padding-top: 1rem;
    padding-left: 1.6rem;
    padding-right: 0.8rem;
    font-family: 'Nunito';
    font-size: 1rem;
    margin-bottom: 2rem;
}
.about-text-bold{
    display: inline;
    font-weight: 700;
}
.about-image-container{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.about-image{
    width: 50%;
    margin-bottom: 2rem;
}
#about-image-slide {
    animation: aboutImageSlide 2.2s ease-in-out 1 none;
}

@keyframes aboutImageSlide {
    from {opacity: 0; margin-right: -10rem;}
    to {opacity: 1; margin-right: 0rem;}
}
@-webkit-keyframes aboutImageSlide {
    from {opacity: 0; margin-right: -10rem;}
    to {opacity: 1; margin-right: 0rem;}
}
@-o-keyframes aboutImageSlide {
    from {opacity: 0; margin-right: -10rem;}
    to {opacity: 1; margin-right: 0rem;}
}
@-moz-keyframes aboutImageSlide {
    from {opacity: 0; margin-right: -10rem;}
    to {opacity: 1; margin-right: 0rem;}
}
@-ms-keyframes aboutImageSlide {
    from {opacity: 0; margin-right: -10rem;}
    to {opacity: 1; margin-right: 0rem;}
}

@media (min-width: 900px) {
    .gif-stripe{
        flex-direction: row;
        height: 20rem;
        object-fit: contain;
        overflow: hidden;
    }
    .gifs{
        width: 30%;
        padding-bottom: 1.5rem;
        margin-top: -1rem;
    }
    .about-title{
        padding-top: 3rem;
    }
    .about-page-container{
        display: flex;
        flex-direction: row;
    }
    .about-text{
        font-size: 1.2rem;
    }
    .about-image{
        margin-bottom: 7rem;
    }
}
