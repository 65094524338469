.portfolio-container{
    background-color: #EAECF4;
    padding-bottom: 3rem;
}

.portfolio-title{
    font-family: 'Bebas Neue';
    font-weight: 700;
    font-size: 4rem;
    padding-left: 1.6rem;
    padding-top: 1.2rem;
}
.videos-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.videos-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}
.videos-thumbnail{
    align-self: center;
    justify-self: center;
    margin-top: 1.6rem;
    width: 70%;
    cursor: pointer;
}
.videos{
    width: 25rem;
    height: 15rem;
}
.popup-videos-overlay{
    background: rgba(0, 0, 0, 0.5);
}

@media (min-width: 900px) {
    .videos-container{
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-self: center;
        justify-content: center;
        align-content: center;
        padding-bottom: 4rem;
    }
    .videos-section {
        display: block;
        padding-left: 10%;
    }
    .videos-thumbnail {
        margin-right: 3rem;
        width: 20rem;
    }
    .videos{
        width: 35rem;
        height: 25rem;
    }
}

