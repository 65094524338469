.main-header{
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #EAECF4;
    padding-bottom: 0.8rem;
}
.header-logo{
    display: none;
}
.header-buttons-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.menu-buttons{
    text-decoration: none;
    border-style: none;
    background: none;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.2rem;
    margin-left: 1.2rem;
    margin-top: 0.7rem;
    cursor: pointer;
    color: #000000;
    align-self: center;
}
#contact{
    color: #FFCC00;
}
.header-icons-container{
    display: flex;
    width: 67%;
    justify-content: flex-end;
    margin-right: 0.4rem;
}
.header-icon{
    text-decoration: none;
    border-style: none;
    background: none;
    margin-top: 0.7rem;
    margin-left: 0.6rem;
    margin-right: 0.5rem;
    cursor: pointer;
    justify-self: center;
    align-self: center;
}
.header-icon + .header-icon {
    margin-right: 0.7rem;
}
.logo-container{
    width: 100vw;
    height: 100vh;
    background: url('../../assets/background.jpg') no-repeat center center scroll;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    position: static;
}
.logo{
    align-self: center;
    justify-self: center;
    width: 26rem;
    height: 26rem;
    background: rgba(0, 0, 0, 0.2);
    animation: fadeLogo 1.5s ease-in-out 1 normal;
}
@keyframes fadeLogo {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-webkit-keyframes fadeLogo {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-o-keyframes fadeLogo {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-moz-keyframes fadeLogo {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-ms-keyframes fadeLogo {
    from {opacity: 0;}
    to {opacity: 1;}
}

@media (min-width: 900px) {
    .main-header{
        padding-bottom: 0.6rem;
    }
    .header-logo {
        display: flex;
        align-self: flex-start;
        justify-self: center;
        width: 4.8rem;
        height: 4.8rem;
        margin-left: 30%;
        margin-right: 4rem;
    }
    .header-buttons-container{
        width: 20%;
    }
    .menu-buttons{
        font-size: 1.2rem;
    }
}
